import { Link } from 'gatsby';
import React from 'react';

const FeatureItem = function({ spanText, mainText, hasSeperator = false, comingSoon = false })
{

    const comingSoonText = comingSoon ? (
        <Link to={"/features/coming-soon"}>
            <span class="h4">*</span> 
        </Link>
    ) : null;

    if(!hasSeperator)
    {

        return <li><span>{spanText}</span> {mainText} {comingSoonText}</li>;
    
    }

    return <li><hr/><span>{spanText}</span> {mainText} {comingSoonText}</li>;

};

export default FeatureItem;