import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import React from "react";

import FeatureItem from './FeatureItem';

const ticketAndForumSupport = <FeatureItem spanText={'Ticket & Forum Support'} mainText={''} hasSeperator={true} />;
const priorityTicketAndForumSupport = <FeatureItem spanText={'Priority Ticket & Forum Support'} mainText={''} hasSeperator={true} />;

const ComingSoonPlaceholder = function({ show })
{

  if(!show)
  {

    return (
      <div>
        <h3>
        &nbsp;
        </h3>
      </div>
    );

  }

  return <div>
    <h3>
      <Link to={"/features/coming-soon"} >
        *<span class="label primary-label -blue">Coming Soon</span>
      </Link>
    </h3>
  </div>;

};

const planFeatures = {
  litePlan: [
    <FeatureItem spanText={'1'} mainText={'Software User'} />,
    <FeatureItem spanText={'Full'} mainText={'Event Tracking'} />,
    <FeatureItem spanText={'Task/Meetings'} mainText={'Manager'} comingSoon={true} />,
    <FeatureItem spanText={'Unlimited'} mainText={'Events, Clients, Venues'} />,
    ticketAndForumSupport,
    <ComingSoonPlaceholder show={true} />
  ],
  plusPlan: [
    <FeatureItem spanText={'All Features'} mainText={'From Lite Plan'} />,
    <FeatureItem spanText={'Enquiry + Lead'} mainText={'Tracking'} />,
    <FeatureItem spanText={'Financial'} mainText={'System'} />,
    <FeatureItem spanText={'iCalendar'} mainText={'Integrations'} />,
    ticketAndForumSupport,
    <ComingSoonPlaceholder show={false} />,
  ],
  professionalPlan: [
    <FeatureItem spanText={'All Features'} mainText={'From Plus Plan'} />,
    <FeatureItem spanText={'Automation'} mainText={'Engine'} comingSoon={true} />,
    <FeatureItem spanText={'Staff'} mainText={'Management'} />,
    <FeatureItem spanText={'Inventory'} mainText={'System'} />,
    priorityTicketAndForumSupport,
    <ComingSoonPlaceholder show={true} />,
  ]
};

export default function Pricing() {

    return (
        <div className="container">
        <div className="row">

          <div className="col-xs-12 text-center">
            <h1 className="heading">Simple Pricing<br /> <small>Upgrade/downgrade anytime.</small>
            <br/>
            <AnchorLink to={"/pricing#comparisionTable"} stripHash>
              <button className="btn blue" type="button">Compare Plans</button>
            </AnchorLink>
            </h1>
            
          </div>

          <div className="col-xs-12 pricing-list">
            <ul className="pricing-list-wrapper row">
              <li className="pricing-table -best col-md-3">
                <header className="pricing-header">
                  <h3 className="package-type">Lite Plan</h3>
                </header>
                <div className="pricing-content">
                  <ul className="package-perks">
                    {planFeatures.litePlan.map(function(item) {

                      return item;

                    })}
                  </ul>
                </div>
                <div className="pricing-cta">
                  <span className="currency">&pound;</span>
                  <span className="value">7.95</span>
                  <span className="cycle">Per<br/>month</span>
                  <a href="https://app.djmanager.co.uk/register?trial_plan=litePlan" className="btn ghost-btn -blue">Choose</a>
                </div>
              </li>

              <li className="pricing-table -best col-md-3">
                <header className="pricing-header">
                  <h3 className="package-type">Plus Plan</h3>
                </header>
                <div className="pricing-content">
                  <ul className="package-perks">
                  {planFeatures.plusPlan.map(function(item) {

                    return item;

                  })}
                  </ul>
                </div>
                <div className="pricing-cta">
                  <span className="currency">&pound;</span>
                  <span className="value">12.95</span>
                  <span className="cycle">Per<br/>month</span>
                  <a href="https://app.djmanager.co.uk/register?trial_plan=plusPlan" className="btn ghost-btn -blue">Choose</a>
                </div>
              </li>

              <li className="pricing-table -best col-md-3">
                <header className="pricing-header">
                  <h3 className="package-type">Pro Plan</h3>
                </header>
                <div className="pricing-content">
                  <ul className="package-perks">
                  {planFeatures.professionalPlan.map(function(item) {

                    return item;

                  })}
                  </ul>
                </div>
                <div className="pricing-cta">
                  <span className="currency">&pound;</span>
                  <span className="value">17.95</span>
                  <span className="cycle">Per<br/>month</span>
                  <a href="https://app.djmanager.co.uk/register?trial_plan=professionalPlan" className="btn ghost-btn -blue">Choose</a>
                </div>
              </li>

              <li className="pricing-table -popout col-md-3">
                <header className="pricing-header">
                  <h3 className="package-type">Custom Plan</h3>
                </header>
                <div className="pricing-content">
                  <ul className="package-perks">
                    <li><span>All Features</span> from Pro Plan</li>
                    <li>Perfect for <span>larger organisations</span></li>
                    <li>Fully suited to <span>Your Business Needs</span></li>
                    <li>Includes an <span> Onboarding Service</span></li>                    
                    <li><hr/><span>Dedicated Support</span></li>
                    <ComingSoonPlaceholder show={false} />
                  </ul>
                </div>
                <div className="pricing-cta">
                  <span className="currency">&pound;</span>
                  <span className="value">-</span>
                  <span className="cycle">Per<br/>month</span>
                  <Link to={"/contact-us"} className="btn ghost-btn -blue">Contact Us</Link>
                </div>
              </li>
            </ul>
          </div>

        </div>
      </div>
    );

};